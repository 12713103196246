import React, { useState } from "react";
import { List, Checkbox, ListItem } from "@mui/material/";

import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Typography from "@mui/material/Typography";

function SearchResultsList(props) {
  const truncateFileName = (str) => {
    str = JSON.stringify(str);
    return str.length > 10 ? '"' + "..." + str.substring(1, 105) + '..."' : str;
  };

  const truncateTitle = (str) => {
    return str.length > 100 ? str.substring(0, 90).trim() + "..." : str.trim();
  };

  function roundScore() {
    let percentage = (Math.round(props.Score * 100) / 100) * 100;

    return Math.floor(percentage) + "%";
  }

  return (
    <List sx={{ width: "78%" }} className="fadePanel" id="listStyle">
      <ListItem alignItems="flex-start">
        <Typography
          variant="body1"
          style={{
            color: "#2C4D71",
            fontSize: "1.1em",
            marginLeft: "15px",
            marginTop: "5px",
          }}
        >
          <b>Download:</b>
        </Typography>

        <ListItemText
          primary={
            <a
              href={
                "https://centcomservices.ais.saicds.com:8080" +
                props.Data.Meta.SourceFile.replace("/files", "/ankura")
              }
              target="_blank"
              style={{
                color: "#619085",
                marginLeft: "35px",
                fontSize: "1.2em",
              }}
            >
              {truncateTitle(props.FileName)}
            </a>
          }
        />
      </ListItem>

      <ListItem alignItems="flex-start">
        <ListItemText
          primary={
            <span>
              <b
                style={{
                  color: "#2C4D71",
                  fontSize: "1.1em",
                  marginLeft: "15px",
                  marginTop: "1px",
                }}
              >
                Confidence:
              </b>{" "}
              <b
                style={{
                  fontSize: "1.3em",
                  marginLeft: "20px",
                  marginTop: "1px",
                  color: "#82368C",
                }}
              >
                {roundScore()}
              </b>
            </span>
          }
          secondary={
            <React.Fragment>
              <Typography
                sx={{ display: "inline" }}
                component="span"
                variant="body1"
                color="text.primary"
              >
                <br />
                <b
                  style={{
                    color: "#2C4D71",
                    fontSize: "1.1em",
                    marginLeft: "15px",
                    marginTop: "1px",
                  }}
                >
                  Context:
                </b>{" "}
                &nbsp;&nbsp;&nbsp;&nbsp;{" "}
                <i
                  style={{
                    fontSize: "1.1em",
                    marginLeft: "25px",
                    marginTop: "1px",
                    color: "#706F6F",
                  }}
                >
                  {truncateFileName(props.Context.replace(/\n/g, " "))}
                </i>
              </Typography>
            </React.Fragment>
          }
        />
      </ListItem>
      {/* <br /> */}
    </List>
  );
}

export { SearchResultsList };
