import React, { useState, useEffect, useReducer } from "react";
import {
  Grid,
  TextField,
  FormHelperText,
  Button,
  Typography,
} from "@mui/material/";
import axios from "axios";
import { getFoiaCaseData } from "../service/services";
import { useForm } from "react-hook-form";
import { SEARCH_ICON, ADD_ICON } from "../assets/icons/IconList";
import { SearchResultsList } from "../components/list";
import ConfidenceSlider from "../components/slider";

export default function Search() {
  useEffect(() => {
    retrieveData();

    function onKeyUp(e) {
      if (e.key === "Enter") {
        e.preventDefault();
        document.getElementById("searchBtn").click();
      }
    }

    window.addEventListener("keyup", onKeyUp);

    return () => {
      window.removeEventListener("keyup", onKeyUp);
    };
  }, []);

  const [caseSelected, setSelectedCase] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [showSearchResults, setShowSearchResults] = React.useState(false);
  const [showSelectedButton, setShowSelectedButton] = React.useState(false);
  const [selectedFiles, setSelectedFiles] = React.useState([]);
  const [processMessage, setProcessMessage] = React.useState(false);
  const [confidenceGage, setConfidenceGage] = React.useState(0);

  const [state, setState] = useState({
    data: [],
    cases: [],
    question: "",
    showCards: false,
    filterOption: "extractive",
    extractiveView: false,
    generativeView: false,
    dropDownValue: "",
    hideTyping: false,
    query_text: "",
    mode: "",
    count: "",
    index: "",
    score_filter: 0.2,
    loading: false,
    selectedFOIACase: "1111",
  });

  // Service Call
  const retrieveData = () => {
    getFoiaCaseData()
      .then((response) => {
        setState({
          cases: response.data.cases,
        });
        console.log(response.data.cases);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const [searchQuery, setSearchQuery] = React.useState([]);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const initForm = {
    data: [],
    query_text: "",
    mode: "",
    count: "",
    index: "",
    score_filter: 0.2,
  };

  const [formVal, dispatch] = useReducer(
    (curVal, newVal) => ({ ...curVal, ...newVal }),
    initForm
  );

  // Service Calls
  const postQuerySearch = (data) => {
    console.log("Input: ", data.questionSearch);
    console.log("Score: ", confidenceGage);

    setLoading(true);

    axios
      .post(`https://centcomservices.ais.saicds.com:8003/query`, {
        query: data.questionSearch,
        threshold: confidenceGage,
        params: {},
        debug: false,
      })
      .then((response) => {
        console.log(response.data);
        setLoading(false);
        setSearchQuery(response.data.answers);
        setShowSearchResults(true);
        setShowSelectedButton(true);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const postAddSelectedFiles = () => {
    axios
      .post(`https://centcomservices.ais.saicds.com:8085/addfilesbycase/`, {
        casenumber: sessionStorage.getItem("CaseNumber"),
        files: selectedFiles,
      })
      .then((response) => {
        console.log("Selected Files: ", response.data);
        setTimeout(() => {
          setProcessMessage(true);
        }, 500);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const searchHandler = (data) => {
    console.log(data);

    postQuerySearch(data);
  };

  const registerOptions = {
    searchInput: {
      required: "Please enter a valid query",
      minLength: {
        value: 3,
        message: "Search query must be at least 3 characters in length",
      },
    },
  };

  // Call backs
  const handleFileSelection = (files) => {
    setSelectedFiles([...selectedFiles, files]);
  };

  const handleConfidenceSelection = (score) => {
    console.log(score);
    setConfidenceGage(score);
  };

  return (
    <div className="searchContainer">
      <Grid container xs={12}>
        <Grid item xs={6} style={{ margin: "0 auto" }}>
          <FormHelperText className="floating" style={{ margin: "-70px" }}>
            {errors?.questionSearch ? (
              <span className="validationBubble bottom">
                {errors.questionSearch.message}
              </span>
            ) : null}
          </FormHelperText>
        </Grid>

        <Grid item xs={12}>
          {/* Search Bar */}
          <Grid container xs={12} spacing={1}>
            <Grid item xs={2} />
            <Grid item xs={8}>
              <Typography
                variant="h5"
                sx={{
                  fontWeight: "700",
                  color: "#2C4D71",
                }}
              >
                Natural Language Search
              </Typography>
              <div className="spacer" />
            </Grid>
            <Grid item xs={2} />

            <Grid item xs={2} />
            <Grid
              item
              xs={8}
              className="searchBox"
              style={{ margin: "0 auto" }}
            >
              <TextField
                className="input"
                sx={{
                  "& fieldset": { border: "none" },
                }}
                autoFocus
                placeholder={"Search ..."}
                name="questionSearch"
                onChange={(e) => e.target.value}
                {...register("questionSearch", registerOptions.searchInput)}
              />

              {/* Search Button */}
              <Button
                type="submit"
                id="searchBtn"
                variant="contained"
                onClick={handleSubmit(searchHandler)}
                className="searchButton"
                style={{ float: "right" }}
              >
                <SEARCH_ICON color={"#333"} />
              </Button>
            </Grid>

            <Grid item xs={2} />

            <Grid
              item
              xs={12}
              style={{
                margin: "0 auto",
                textAlign: "center",
                justifyContent: "center",
              }}
            >
              {processMessage === true ? (
                <div>
                  <Typography className="messageBubble bottom floating">
                    Files have been successfully added to Case number{" "}
                    <b style={{ color: "#e6ff6c" }}>
                      {sessionStorage.getItem("CaseNumber")}
                    </b>
                  </Typography>
                </div>
              ) : (
                ""
              )}
            </Grid>

            <Grid item style={{ margin: "-10px auto" }}>
              <ConfidenceSlider
                retrieveConfidenceGage={handleConfidenceSelection}
              />
            </Grid>

            {/* Search Results */}
            <Grid
              // style={{
              //   overflow: showSearchResults === true ? "scroll" : "hidden",
              // }}
              container
              xs={12}
              spacing={2}
              sx={{
                width: "100%",
                maxWidth: 900,
                position: "relative",
                height: "100%",
                borderRadius: "7px",
                marginTop: "0",
                "& ul": { padding: 0 },
              }}
            >
              <Grid item xs={12}>
                <div className="spacer" />
                <div className="spacer" />
                {loading === true ? (
                  <div class="loadingContainer">
                    <div class="loading">
                      <span></span>
                      <span></span>
                      <span></span>
                      <span></span>
                      <span></span>
                      <span></span>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </Grid>
              <Grid item xs={2} />
              <Grid item xs={10}>
                <div className="floating">
                  {showSearchResults === true ? (
                    <span className="searchResultsBubble bottom">
                      Search Results ({searchQuery.length})
                    </span>
                  ) : (
                    ""
                  )}
                </div>
              </Grid>
              {showSearchResults === true ? (
                searchQuery.map((item) => {
                  return (
                    <Grid
                      item
                      xs={10}
                      spacing={5}
                      className="marginCenter fadePanel"
                    >
                      <SearchResultsList
                        FileName={item.Name}
                        Score={item.Score}
                        Context={item.Context}
                        Path={item.Meta.path}
                        Data={item}
                        retrieveFileSelection={handleFileSelection}
                      />
                      <div className="spacer" />
                      {/* <div className="dividerShape" />
                      <div className="spacer" /> */}
                    </Grid>
                  );
                })
              ) : (
                <span></span>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
