import React from "react";
import { Grid } from "@mui/material";

const Footer = () => {
  return (
    <div>
      <Grid container sx={12} className="footer"></Grid>
    </div>
  );
};

export default Footer;
