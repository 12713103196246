import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { StyledEngineProvider } from "@mui/material/styles";
import AppWrapper from "./template/appTemplate";
import "./assets/sheets/main.scss";
import Search from "./search";

function App() {
  return (
    <StyledEngineProvider injectFirst>
      <div className="appContainer">
        <AppWrapper>
          <BrowserRouter>
            <Routes>
              <Route path="/" exact element={<Search />} />
              <Route path="/search" exact element={<Search />} />
            </Routes>
          </BrowserRouter>
        </AppWrapper>
      </div>
    </StyledEngineProvider>
  );
}

export default App;
