import React from "react";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import UploadIcon from "@mui/icons-material/Upload";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditIcon from "@mui/icons-material/Edit";
import LockIcon from "@mui/icons-material/Lock";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

import WorkIcon from "@mui/icons-material/Work";
import NumbersIcon from "@mui/icons-material/Numbers";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import CallReceivedIcon from "@mui/icons-material/CallReceived";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import DeleteIcon from "@mui/icons-material/Delete";

const Search = (props) => {
  return (
    <SearchIcon style={{ fontSize: props.iconSize, color: props.iconColor }} />
  );
};

const Delete = (props) => {
  return (
    <DeleteIcon style={{ fontSize: props.iconSize, color: props.iconColor }} />
  );
};

const BackArrow = (props) => {
  return (
    <ArrowBackIosNewIcon
      style={{ fontSize: props.iconSize, color: props.iconColor }}
    />
  );
};

const File = (props) => {
  return (
    <FileCopyIcon style={{ fontSize: props.iconSize, color: "#82368C" }} />
  );
};

const Edit = (props) => {
  return (
    <EditIcon style={{ fontSize: props.iconSize, color: props.iconColor }} />
  );
};

const Lock = (props) => {
  return (
    <LockIcon style={{ fontSize: props.iconSize, color: props.iconColor }} />
  );
};

const Add = (props) => {
  return (
    <AddIcon style={{ fontSize: props.iconSize, color: props.iconColor }} />
  );
};

const Upload = (props) => {
  return (
    <UploadIcon style={{ fontSize: props.iconSize, color: props.iconColor }} />
  );
};

const MenuIcon = (props) => {
  return <Menu style={{ fontSize: props.iconSize, color: props.iconColor }} />;
};

const MenuItemIcon = (props) => {
  return (
    <MenuItem style={{ fontSize: props.iconSize, color: props.iconColor }} />
  );
};

const MoreVerticalIcon = (props) => {
  return (
    <MoreVertIcon
      style={{ fontSize: props.iconSize, color: props.iconColor }}
    />
  );
};

const CaseOfficer = (props) => {
  return (
    <WorkIcon style={{ fontSize: props.iconSize, color: props.iconColor }} />
  );
};

const CaseNumber = (props) => {
  return (
    <NumbersIcon style={{ fontSize: props.iconSize, color: props.iconColor }} />
  );
};

const FileCount = (props) => {
  return (
    <NoteAddIcon style={{ fontSize: props.iconSize, color: props.iconColor }} />
  );
};

const DateReceived = (props) => {
  return (
    <CallReceivedIcon
      style={{ fontSize: props.iconSize, color: props.iconColor }}
    />
  );
};

const DaysTillClose = (props) => {
  return (
    <WatchLaterIcon
      style={{ fontSize: props.iconSize, color: props.iconColor }}
    />
  );
};

const DueDate = (props) => {
  return (
    <EventAvailableIcon
      style={{ fontSize: props.iconSize, color: props.iconColor }}
    />
  );
};

export const Icon = ({ component, style, ...props }) =>
  component({
    style: {
      width: "33px",
      height: "33px",
      borderRadius: "10px",
      textAlign: "center",
      padding: "5px",
      boxShadow: "#819dadff 1px 2px 4px",
      ...style,
    },
    ...props,
  });

export const SEARCH_ICON = (props) => Icon({ component: Search, ...props });
export const FILE_ICON = (props) => Icon({ component: File, ...props });
export const EDIT_ICON = (props) => Icon({ component: Edit, ...props });
export const LOCK_ICON = (props) => Icon({ component: Lock, ...props });
export const ADD_ICON = (props) => Icon({ component: Add, ...props });
export const UPLOAD_ICON = (props) => Icon({ component: Upload, ...props });
export const MENU_ICON = (props) => Icon({ component: MenuIcon, ...props });
export const DELETE_ICON = (props) => Icon({ component: Delete, ...props });

export const MENU_ITEM_ICON = (props) =>
  Icon({ component: MenuItemIcon, ...props });
export const MORE_VERTICAL_ICON = (props) =>
  Icon({ component: MoreVerticalIcon, ...props });
export const BACK_ARROW = (props) => Icon({ component: BackArrow, ...props });
export const CASE_OFFICER = (props) =>
  Icon({ component: CaseOfficer, ...props });
export const CASE_NUMBER = (props) => Icon({ component: CaseNumber, ...props });
export const FILE_COUNT = (props) => Icon({ component: FileCount, ...props });
export const DATE_RECEIVED = (props) =>
  Icon({ component: DateReceived, ...props });
export const DAYS_TOCLOSE = (props) =>
  Icon({ component: DaysTillClose, ...props });
export const DUE_DATE = (props) => Icon({ component: DueDate, ...props });
