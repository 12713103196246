import * as React from "react";
import Box from "@mui/material/Box";
import { Slider, Typography } from "@mui/material/";

const marks = [
  {
    value: 0,
    label: "0%",
  },
  {
    value: 0.1,
    label: "10%",
  },
  {
    value: 0.2,
    label: "20%",
  },
  {
    value: 0.3,
    label: "30%",
  },
  {
    value: 0.4,
    label: "40%",
  },
  {
    value: 0.5,
    label: "50%",
  },
  {
    value: 0.6,
    label: "60%",
  },
  {
    value: 0.7,
    label: "70%",
  },
  {
    value: 0.8,
    label: "80%",
  },
  {
    value: 0.9,
    label: "90%",
  },
  {
    value: 1,
    label: "100%",
  },
];

export default function ConfidenceSlider(props) {
  const handleSliderChange = (event, newValue) => {
    props.retrieveConfidenceGage(newValue);
  };

  return (
    <Box sx={{ width: 500, marginTop: "50px" }}>
      <Typography gutterBottom sx={{ textAlign: "center", color: "#619085" }}>
        <b>Minimum Confidence Level</b>
      </Typography>

      <Slider
        aria-label="Confidence Level"
        defaultValue={0}
        step={0.01}
        min={0}
        max={1}
        marks={marks}
        valueLabelDisplay="on"
        onChange={handleSliderChange}
        sx={{
          color: "#82368C",
        }}
      />
    </Box>
  );
}
