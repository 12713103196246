import React from "react";
import { Grid } from "@mui/material";
import Ancura from "../assets/images/FullLogo.png";

const Header = () => {
  return (
    <div>
      <Grid container xs={12} sm={12} md={12} lg={12}>
        <Grid xs={12} className="rightAlign">
          <a href={"/"}>
            <img src={Ancura} alt="Ancura Logo" />
          </a>
        </Grid>
      </Grid>
    </div>
  );
};

export default Header;
